import React, { useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import { bool } from 'prop-types';
import { Helmet } from 'react-helmet';

import { BiDownload, BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Text,
  Center,
  Flex,
  Divider,
  useToast,
  Button,
  Spinner,
  Heading,
} from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import loadable from '@loadable/component';

import { DEFAULT_PROPS, TEXT_PROPS } from '@/constants/default-props';
import { ENABLE_SHIFT_TIME_RECEPTION, ENABLE_QR_DESC } from '@/constants/feature-flags';

import { useGuest } from '@/context/guest';
import useLang from '@/hooks/useLang';
import useShift from '@/hooks/useShift';
import useQueryString from '@/hooks/useQueryString';

import QRButton from '../../QRCodeButton';

// @important - please do don't change to CDN github
// we will got failed export image because CORS issue
import IMG_QR_COVER from '@/assets/bg/qrcard.jpg';

import { THE_BRIDE, WEDDING } from '@/constants';
import txt from '../locales';

const LazyExportPNG = loadable(() =>
  import(/* webpackChunkName: "invitato-export-png" */ '../ExportPNG'),
);

const FONT_BODY = {
  ...TEXT_PROPS.body,
  textAlign: 'left',
  color: '#000',
  fontFamily: 'Jost',
  fontSize: 'sm',
};

const FONT_LABEL = {
  ...TEXT_PROPS.sub_title,
  fontSize: 'xs',
  fontWeight: 'light',
  color: '#000',
  textAlign: 'left',
  fontFamily: 'Jost',
};

/**
 * Function to render QRCard version 2
 * Support:
 * - Download QR as PNG
 * - Open QR in new page
 */
function QRCardSection({ isDirectLink, ...rest }) {
  const toast = useToast();
  const { getQueryValue } = useQueryString();
  const isAutoDownload = getQueryValue('autoDownload') == '1';

  const lang = useLang();
  const ticketRef = useRef(null);
  const isShowToaster = useRef(false);
  const { isLoading, guest } = useGuest();
  const { code, name, desc, shift, guest_quota, guest_confirm } = guest;
  const shiftTime = useShift(shift);

  useEffect(() => {
    if (
      !isLoading &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !isDirectLink &&
      !isShowToaster.current &&
      !isAutoDownload
    ) {
      isShowToaster.current = true;
      toast({
        isClosable: true,
        status: 'warning',
        duration: null,
        description: (
          <Text fontSize="sm">
            <span dangerouslySetInnerHTML={{ __html: txt.darkmodeDesc[lang] }} />
          </Text>
        ),
      });
    }
  }, []);

  // IF code is not found, just ignore it
  if (!code) return null;

  return (
    <Box bgColor="bgPrimary" padding="16px 0" {...rest}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Jost:wght@200;300;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {isLoading ? (
        <Center height="100px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ) : (
        <>
          <div>
            <Box ref={ticketRef} m="0 auto" maxW="300px" bgColor="bgPrimary" padding="6px">
              <Box bgColor="white" padding="6px">
                {/* QRCODE COVER SECTION */}
                <Box
                  bgImage={`url(${IMG_QR_COVER})`}
                  width="100%"
                  height="160px"
                  bgSize="cover"
                  bgPos="center"
                >
                  <Box bgColor="blackAlpha.600">
                    <Center height="160px">
                      <Box color="mainColorText" textAlign="center">
                        <Text {...TEXT_PROPS.sub_title} fontSize="xs" textTransform="uppercase">
                          {txt.title[lang]}
                        </Text>
                        <Heading {...TEXT_PROPS.title} size="lg" fontSize="42px" margin="4px 0">
                          {THE_BRIDE}
                        </Heading>
                        <Text {...TEXT_PROPS.sub_title} fontSize="sm">
                          {WEDDING.fullDate}
                        </Text>
                      </Box>
                    </Center>
                  </Box>
                </Box>
                {/* DETAIL QRCODE */}
                <Flex padding="12px 4px" justifyContent="space-between">
                  <Box flex="1.3">
                    <Text {...FONT_LABEL} fontSize="xs">
                      {txt.to[lang]}
                    </Text>
                    <Text {...FONT_BODY}>{name}</Text>
                    {ENABLE_QR_DESC && desc.toLowerCase().includes('vip') && (
                      <>
                        <Text {...FONT_LABEL} fontSize="xs" mt="8px">
                          {txt.desc[lang]}
                        </Text>
                        <Text {...FONT_BODY}>{desc}</Text>
                      </>
                    )}
                    <Text {...FONT_LABEL} fontSize="xs" mt="8px">
                      Total Guest
                    </Text>
                    <Text {...FONT_BODY}>{`${guest_confirm || guest_quota} person`}</Text>
                    {/* @TODO: disabled for now */}
                    {shift && ENABLE_SHIFT_TIME_RECEPTION && false && (
                      <Text {...FONT_BODY}>{`Shift ${shift} (${shiftTime})`}</Text>
                    )}
                  </Box>
                  <Box flex="1">
                    <Box float="right">
                      <QRCode value={code} size={125} />
                    </Box>
                  </Box>
                </Flex>
                <Text {...FONT_BODY} textAlign="center" fontSize="xs">
                  {txt.importantDesc[lang]}
                </Text>
              </Box>
              <Divider opacity="1" height="2px" bgColor="bgPrimary" border="none" />
              {/* Copyright Section */}
              <Box {...FONT_BODY} textAlign="center" bgColor="white" padding="8px 0">
                <Text fontSize="xs">Organized & Generated by Invitato</Text>
                <Text fontSize="xs">{`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
              </Box>
            </Box>
          </div>
          <Box color="mainColorText">
            <Text
              {...TEXT_PROPS.body}
              margin="16px 0"
              dangerouslySetInnerHTML={{ __html: txt.downloadDesc[lang] }}
            />
            <Center>
              {isDirectLink ? (
                <QRButton
                  text="Download E-ticket"
                  customQueries="&autoDownload=1"
                  {...DEFAULT_PROPS.button}
                />
              ) : (
                <LazyExportPNG
                  componentRef={ticketRef}
                  autoDownload={isAutoDownload}
                  variant="outline"
                  marginTop="16px"
                  leftIcon={<BiDownload />}
                  type="button"
                  size="sm"
                  fontWeight="normal"
                  color="mainColorText"
                />
              )}
            </Center>
            {!isDirectLink && (
              <Center>
                <Button
                  variant="outline"
                  marginTop="16px"
                  leftIcon={<BiArrowBack />}
                  type="button"
                  size="sm"
                  fontWeight="normal"
                  color="mainColorText"
                  onClick={() => navigate(-1)}
                >
                  {txt.back[lang]}
                </Button>
              </Center>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

QRCardSection.propTypes = {
  isDirectLink: bool,
};

QRCardSection.defaultProps = {
  isDirectLink: true,
};

export default React.memo(QRCardSection);
