import get from '@/helpers/getLocale';

export default {
  title: get('Kartu Akses Masuk', 'Entrance Access Card'),
  to: get('Nama', 'Name'),
  desc: get('Keterangan', 'Description'),
  important: get('Penting', 'Important'),
  back: get('Kembali ke Undangan', 'Back to Invitation'),
  importantDesc: get(
    'Silakan tunjukkan QR Code disamping kepada usher yang bertugas di lokasi acara.',
    'Please show the QR Code to enter the event venue.',
  ),
  downloadDesc: get(
    'Silakan download <b>Kartu Akses Masuk</b> diatas, untuk mempermudah proses scanning di lokasi acara:',
    'Please download the <b>Entrance Access Card</b> above, to simplify the scanning process at the event location:',
  ),
  darkmodeDesc: get(
    'Oops, saat ini Anda menggunakan mode gelap (<i>dark mode</i>). Mohon untuk <b>menonaktifkan darkmode terlebih dulu</b> sebelum screenshot atau <b>download kartu akses masuk</b> agar QRCode dapat terbaca maksimal.',
    'Oops, you are currently using <i>dark mode</i>. Please <b>turn off dark mode first</b> before taking screenshot or <b>downloading the entrance access card</b> so the QRCode can be read properly.',
  ),
};
